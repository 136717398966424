import styled, { css } from 'styled-components';
import { DropdownButton, DropdownItem, Modal} from 'react-bootstrap';
const BoldFont = 'Rubik-Medium';

const Layout = styled.div`
width: 100%;
padding: 25px;

> div: first-child {
  width: 100%;
  max-width: 1246px;
  margin: auto;
}
`;

const MainContainer = styled.div`
width: 100%;
display: flex
> div: first-child {
  width: 32%;
}
> div: last-child {
    width: 68%;
    padding-left: 12px;
  }
`;

const TopPointsContainer = styled.div`
width: 100%;
display: block;
margin-top: ${({ marginTop }) => marginTop ? marginTop : '0px'};
`;

const TopPointsCard = styled.div`
width: 100%;
display: flex;
margin-bottom 15px;
min-height: 80px;
cursor: pointer;
> div: first-child {
    width: calc(100% - 13px);
    display: flex;
    background: white;
    background: ${({ background }) => background ? background : '#0D4270'};
    border-radius: 6px;
    z-index: 2;
    border: ${({ border }) => border ? border : ''};
    > div: first-child {
        width: 50px;
        height: 50px;
        display: flex;
        margin: auto;
        margin-left: 15px;
        margin-right: 25px;
     >img{
         width: 100%;
         height: 100%;
     }
    }
    > div: last-child {
        display: flex;
        margin: auto;
        margin-left: 0px;
        align-items: center;
        justify-content: center;

        font-family: 'Rubik-Medium';
        font-size: 18px;
        line-height: 24px;
        color:rgba(105, 194, 255, 1);
        color: ${({ color }) => color ? color : '#0D4270'};
    }
}
> div: last-child {
    width: 13px;
    display: flex;
    margin: auto;
    >div{
    width: 22px;
    height: 22px;
    margin-left: -13px;
    transform: rotate(45deg);
    background: ${({ background }) => background ? background : '#0D4270'};
    }
}
`;

const TopPositionCard = styled.div`
width: 100%;
background: linear-gradient(rgb(30, 100, 129) 0%, rgb(13, 66, 92) 60.94%, rgb(2, 44, 67) 100%);
border-radius: 16px;
margin-top: ${({margin}) => margin ? "0px" : '15px'};
`;

const TopPositionProfile = styled.div`
width: 100%;
display: flex;
border-radius: 6px;
padding-top: ${({padding}) => padding ? padding : '0px'};

& > div: first-child{
  width: 33.33%;
}
>div:nth-child(2){
  width: 33.33%;
}
>div:nth-child(3){
  width: 33.33%;
}
`;


const ThirdPositionCard = styled.div`
width: 100%;
display: block;
border-radius: 50%;
margin: auto;
margin-top: 70px;

& > div: first-child{
  width: 116px;
  height: 116px;
  border-radius: 50%;
  display: flex;
  margin: auto;
  >img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  border: 3px solid #FD7175;
  border: ${({color}) => color ? '3px solid #FD7175' : '3px solid #9FC989'};
  background: ${({color}) => color ? color : '#9FC989'};
}
>div:nth-child(2){
  width: 100%;
  display: flex;
  justify-content:center;
  position: relative;
  bottom: 25px;
  >div{
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 3px solid #F6B479;
    border: ${({color}) => color ? '3px solid #FD7175' : '3px solid #9FC989'};
    align-items: center;
    display: flex;
    justify-content: center;
    background: #9FC989;
    background: ${({color}) => color ? color : '#9FC989'};
  >span{
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
 }
}
>div:nth-child(3){
  width: 100%;
  position: relative;
  bottom: 20px;
  padding-bottom: 5px;
  & > div: first-child{
    width: 100%;
    display: flex;
    justify-content:center;
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 19.8px;
    color: #fff;
    margin-top: 10px;
    margin-bottom 10px;
  }
  & > div: last-child{
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content:center;
    font-family: Rubik;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    >p{
      font-family: Rubik;
      font-size: 14px;
      line-height: 19px;
      color: ${({colorText}) => colorText ? colorText : '#fff'};
    }
    >span{
      font-family: Rubik;
      font-size: 14px;
      line-height: 19px;
      color: #fff;
    }
  }
}
`;


const FirstPositionCard = styled.div`
width: 100%;
display: block;
border-radius: 50%;
& > div: first-child{
  width: 100%;
  display: flex;
  justify-content:center;
  >div{
    width: 38px;
    height: 37px;
    margin-bottom: 5px;
  >img{
    width: 100%;
    height: 100%;
  }
}
}
>div:nth-child(2){
  width: 146px;
  height: 146px;
  border-radius: 50%;
  display: flex;
  margin: auto;
  >img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  border: 3px solid #F6B479;
  background: #F6B479;
}

>div:nth-child(3){
  width: 100%;
  display: flex;
  justify-content:center;
  position: relative;
  bottom: 25px;
  >div{
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 3px solid #F6B479;
    align-items: center;
    display: flex;
    justify-content: center;
    background: #F6B479;
  >span{
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
 }
}
>div:nth-child(4){
  width: 100%;
  position: relative;
  bottom: 20px;
  padding-bottom: 5px;
  & > div: first-child{
    width: 100%;
    display: flex;
    justify-content:center;
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 19.8px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  & > div: last-child{
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content:center;
    font-family: Rubik;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    >p{
      font-family: Rubik;
      font-size: 14px;
      line-height: 19px;
      color: ${({colorText}) => colorText ? colorText : '#fff'};
    }
    >span{
      font-family: Rubik;
      font-size: 14px;
      line-height: 19px;
      color: #fff;
    }
  }
}
`;

const TopPositionCardText = styled.div`
width: 100%;
display: block;
& > div: first-child{
  width: 100%;
  display: flex;
  justify-content:center;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 19.8px;
  color: #fff;
  padding-top: 25px;
  margin-bottom: ${({ margin }) => margin ? "0px" : '10px'};
}
`;

const PositionCardsContainer = styled.div`
width: 100%;
display: block;
Margin-top: 15px;
`;

const PositionCards = styled.div`
width: 100%;
display: flex;
background: ${({ background }) => background ? background : '#fff'};
border-radius: 6px;
margin-bottom 10px;
>div:nth-child(1){
  width:50%;
  display: flex;
  >div:nth-child(1){
    width: 60px;
    height: 60px;
    margin: 10px 15px 10px 10px;
    position: relative;
    div{
      >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background: white;
    }

    }
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background: white;
    }
    .captainband{
      position:absolute;
      width:24px !important;
      height:24px !important;
      right:-8px;
      top:-8px;
    }
  }
  >div:nth-child(2){
    display: block;
    margin: auto;
    margin-left: 0px;
    >div:nth-child(1){
      margin-bottom: 10px;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#69C2FF;
      color: ${({ color }) => color ? "#fff" : '#69C2FF'};

    }
    >div:nth-child(2){
      font-family: Rubik;
      font-size: 16px;
      line-height: 20px;
      color: ${({ color }) => color ? "#0D4270" : '#0D4270'};
      display: ${({display}) => display ? '' : 'flex'}
      >div{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: rgb(253, 113, 117);
        margin-left: 5px
      }
    }
    
  }
}
>div:nth-child(2){
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-right: 5px;
  align-items: center;
  padding-right:10px;
  >div:nth-child(1){
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 20px;
    color: ${({ color }) => color ? "#fff" : '#69C2FF'};
  }
  >div:nth-child(2){
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    color: ${({ color }) => color ? "#fff" : '#0D4270'};
  }
  >button{
    border: none;
    background: none;
  >img{
    margin-left: 15px;
    cursor: pointer;
  }
}
}
`;

const Heading = styled.div`
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 24px;
color: #0D4270;
margin-bottom: 15px;
`;

const FitnessActivityCard = styled.div`
width: 100%;
display: flex;
margin-bottom 15px;
min-height: 90px;
cursor: pointer;
> div: first-child {
    width: calc(100% - 13px);
    display: flex;
    background: white;
    background: ${({ background }) => background ? background : '#0D4270'};
    border-radius: 6px;
    z-index: 2;
    border: ${({ border }) => border ? border : ''};
    > div: first-child {
        width: 50px;
        height: 50px;
        display: flex;
        margin: auto;
        margin-left: 15px;
        margin-right: 15px;
     >img{
         width: 100%;
         height: 100%;
     }
    }
    > div: last-child {
       
        margin: auto;
        margin-left: 0px;
        align-items: center;
        justify-content: center;

        font-family: 'Rubik-Medium';
        font-size: 18px;
        line-height: 24px;
        color:rgba(105, 194, 255, 1);
        color: ${({ color }) => color ? color : '#0D4270'};
        > div: first-child {
          font-family: 'Rubik-Medium';
          font-size: 16px;
          line-height: 20px;
          color: ${({ color }) => color ? color : '#fff'};
          margin-bottom 10px;
        }
        > div: last-child {
          >div{
            display: flex;
            div{
              >img{
              width: 30px;
              height: 30px;
              margin-left: -12px;
              &:first-child{margin-left:0px};
              border-radius: 50%;
              border: ${({ profileBorder }) => profileBorder ? profileBorder : '3px solid #fff'};
            }

            }
            >img{
              width: 30px;
              height: 30px;
              margin-left: -12px;
              &:first-child{margin-left:0px};
              border-radius: 50%;
              border: ${({ profileBorder }) => profileBorder ? profileBorder : '3px solid #fff'};
            }
          }
        }

    }
}
> div: last-child {
    width: 13px;
    display: flex;
    margin: auto;
    >div{
    width: 22px;
    height: 22px;
    margin-left: -13px;
    transform: rotate(45deg);
    background: ${({ background }) => background ? background : '#0D4270'};
    }
}
`;

const FitnessTopContainer = styled.div`
width: 100%;
padding: 0px 25px 15px 25px;
`;

const FirstPositionImage = styled.div`
width: 100%;
height: 25px;
>img{
  width: 30px;
  height: 30px;
  position: relative;
  left: 3%;
  top: 5px;
}
`;

const CardWrapper = styled.div`
width: 100%;
height: 900px;
overflow-y: auto;
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-thumb {
  background: transparent !important;
  cursor: pointer !important;
  border-radius: 2.5px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 2.5px !important;
}
`;

const FitnessTopContainerV2 = styled.div`
width: 100%;
padding: 0px 10px 15px 10px;
margin-bottom: 15px
`;

const TopPositionCardTextV2 = styled.div`
width: 100%;
display: flex;
& > div: first-child{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 19.8px;
  color: #fff;
  padding-top: 25px;
  margin-bottom: ${({ margin }) => margin ? "15px" : '10px'};
  padding-top: 15px;
  margin-left: 10px;
}
>button{
  border: none;
  background: none;
>img{
  width: 22px;
  height: 22px;
  margin-top: 0px
  margin-right: 10px;
}
}
`;

const OverallTeamPosition = styled.div`
  width: 100%;
  height: 180px;
  background: #FFFFFF;
  border-radius: 6px;
  .upper-text{
    height: 50px;
    background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
    border-radius: 6px 6px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  .heading{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center; 
    color: #FFFFFF;
    padding-left: 15px;
  }
  .position{
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #F6B479;
    padding-right: 15px;
  }
  }
  .lower-data{
    display: flex;
    .edit-button{
      padding: 15px 20px 10px 15px;
      display: flex;
      flex-direction: column;
      >img{
        width: 80px;
        height: 80px;
      }
      >button{
        border: none;
        background: none;
        display: flex;
        justify-content: space-evenly;
        margin-top: 9px;
        .editText{
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: right;
          text-decoration-line: underline;
          color: #69C2FF;       
        }
      }
    }
  .teamdata-rows{
    width: 84%;
    .row1{
      border-bottom: 1px solid rgba(156, 156, 156, 0.4);
      display: flex;
      justify-content: space-between;
      padding-top: 15px;
      .team-head{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #0D4270;
        padding-bottom: 10px
      }
      .team-name{
        font-family: 'Rubik-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        background: linear-gradient(180deg, #FD7175 0%, #FF5257 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        padding-bottom: 10px
      }
      .team-name2{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #0D4270;
        padding-bottom: 10px
      }
      .team-name3{
        font-family: 'Rubik-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        padding-bottom: 10px
      }
  }
  }
`;

const TeamDetailsStyledModal = styled(Modal)`
  .modal-dialog {
    width:500px;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
    }
    .modal-footer{
      height:80px;
      display:flex;
      justify-content:center;
      align-items:center;
    }
    .modal-body{
      padding: 0px;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
      .teamWrapper{
        width:100%;
        display:flex;
        flex-wrap:wrap;
        height: 379px;
        padding: 25px;
        overflow-x: hidden;
        overflow-y: auto;
        ::-webkit-scrollbar {
          width: 7px;
          height: 45px;
        }
        ::-webkit-scrollbar-thumb {
          background: #c6c2c2;
          cursor: pointer;
          border-radius: 2.5px;
        }
      
        ::-webkit-scrollbar-track {
          background: #f2f0f0;
          border-radius: 2.5px;
        }
      }
      .teamNameDiv{
         width:100%;
        display:flex;
        justify-content:flex-start;
        align-items:center;
         flex-direction:column;
            >lable{
            width:100%;
            display:flex;
            margin-bottom:15px;
            justify-content:flex-start;
            align-items:center;
            font-family: 'Rubik';
               font-style: normal;
             font-weight: 400;
              font-size: 16px;
             line-height: 20px; 
             color: #0D4270;
               >span{color:red;}
            }
            >input{
              background: rgba(156, 156, 156, 0.1);
              border: 1px solid rgba(156, 156, 156, 0.4);
              border-radius:3px;
              font-family: 'Rubik-medium';
              padding:13px 15px;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #0D4270;
              width:100%;
              height:50px;
              outline: none;
             }
           }

        .teamLogoDiv{
          margin:25px 0;
          width:100%;
          display:flex;
          justify-content:flex-start;
          align-items:center;
           flex-direction:column;
           >h4{
            width:100%;text-align:left;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #0D4270;
           }
           .radioButtonDiv{
            width:100%;
            display:flex;
            justify-content:flex-start;
            align-items:center;flex-direction:row;
            span{  width:100%;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */
            display: flex;
            align-items: center;
            /* navy */
            color: #0D4270;
              display:flex;
              justify-content:flex-start;
              align-items:center;
              flex-direction:row;}
           }

          }  
          .selectedDiv{
            display:flex;
            justify-content:flex-start;
            margin-top:17px;
            flex-wrap:wrap;
            width:450px;     
          }
.logoDivInner{ display:flex;
  justify-content:flex-start;
  margin-top:17px;
  width:100%;
  height:150px;
  >img{
    width:150px;
    height:150px;border-radius:6px;
  }
  >button{margin-left:20px;
    background:${({ removeButton }) => removeButton ? "#FD7175" : "#69c2ff"};
    border:none;
    color:white;font-size:18px;
    font-family:rubik-medium;

    width:120px;
    height:50px;border-radius:4px;
    display:flex;
    justify-content:center;
    align-items:center;
   }
 }
}
    @media (max-width: 1000px) {
   width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const CustomTitle = styled(Modal.Title)`
float: left;
width: 100%;
> div {
  float: left;
  width: 100%;
  display: flex;
  > div {
    font-size: 12px;
    color: #a9a9a9;
    display: flex;
  }

  > span {
    font-size: 18px;
    letter-spacing: 0px;
    font-family: rubik-medium;
    color: white;
    width: 70%;
  }
  .closeImage{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 500px) {
  padding: 0 10px;
}
`;

const CustomRadioButton = styled.div`
margin-right:10px;
width:24px;
height:24px;
border:1px solid #9c9c9c
border-radius:50%;
display: flex;
justify-content:center;
align-items:center;
>div{
  width:14px;
  height:14px;
  border-radius:50%;
  background:#fd7175
}
`;

const ImageInput = styled.label`
  color: ${({ Color }) => Color ? Color : 'white'};
  padding: 9px 20px;
  background-color:${({ bgColor }) => bgColor ? bgColor : '#159fc9'};
  border-radius: 30px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  line-height: ${({ lineHeight }) => lineHeight && '1.428571429'};
  margin-top: 10px;
  margin-top: ${({ margin }) => margin ? margin : '10px'};
  font-family: rubik-medium;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  > input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    bottom: ${({ imageReload }) => imageReload && '0'};
  }
  
  ${(props) => props.showAtBottom && css`
    position: absolute;
    left: calc(40% + 20px);
    bottom: 0;

    @media (max-width: 500px) {
      left: 0;
      position: static;
    }
  `}`


const InputImage = styled(ImageInput)`
display: flex;
justify-content: center;
align-items:center;
width:164px;
height:50px;
background-color:#69C2FF;
font-size:18px;
margin:0 25px;
border-radius:3px;
cursor:pointer
  padding: 0;
  margin-bottom: 0;
  > i {
        font-family:rubik-medium;
        font-style:normal;
        font-weight:400;
        font-size:18px;
        color:#fff; 
        text-transform: capitalize;
  }
  @media (max-width: 600px) {
    position: absolute;
    background-color:#c4c4c4;
    margin:0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
    font-size: 10px;
    >i{
      font-size:10px;
    }
  }
`;

const SelectImage = styled.div`
  position:relative;
  width:60px;
  height:60px;margin:0 15px 15px 0;
  border-radius:6px;
  >img{width:60px;
    border-radius:6px;
    background: #69c2ff;
    height:60px;}
    .tick{
      position:absolute;
      top:-7px;
      right:-5px;
      width:22px;
    height:22px;
    border-radius: 50%;
    }
              
`;

const TeamCardV2 = styled.div`
width:100%;
display: flex;
flex-wrap:wrap;
border-radius:6px;

.headingDiv{
font-family: 'Rubik';
font-weight: 400;
font-size: 16px;
line-height: 20px;

display: flex;
align-items: center;
color: #0D4270;
justify-content: space-between; 
  .number{
    color: #69C2FF;
  }
  .max{

color: #9C9C9C;
  }
}

.teamDetails{
display: flex;
width:100%;
flex-direction:column;
justify-content:center;
align-items:center;
background:white;
padding: 5px 0px 0px 0px;
>textarea{
  background: rgba(156, 156, 156, 0.1);
  border: 1px solid rgba(156, 156, 156, 0.4);
  border-radius:3px;
  font-family: 'Rubik-medium';
  padding:13px 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0D4270;
  width:100%;
  height:100px;
  outline: none;
 }
}
`;

const MemberDataV2 = styled.div`
display:flex;
  justify-content:space-between;
  align-items:center;
  width:100%;
  height:90px;
  border-top:${({ border }) => border ? "none" : "1px solid #9c9c9c"}
  .imageDiv{   width:60px;
    height:60px;
    margin-right:15px;
    position:relative;
    border-radius:6px;
    border:${({ captain }) => captain ? "2px solid #69c2ff" : "none"}
    background: #69c2ff;
  img{
    width:100%;
    border-radius:4px;
    height:100%;
  }
}
  .textBox{

    .wellnessChampion{
      width: 100%;
      margin-top:4px;
  >img{
    width: 111px;
    height: 13px;
    display: flex;
  }
  >span{
  font-family: Rubik;
  font-size: 10px;
  line-height: 9px;
  color: #fff;
  margin-top: -11px;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  }}




    >h1{
      margin:0;
      font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #0D4270;
.max{
  color: #FD7175;
 }
    };
    >h2{
      margin:0;
      font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #9C9C9C;
    }
  }

.menueButton{
  margin: auto;
  margin-right: 0px;
  display:flex;
  height:6px;
  width:25px;
  position:relative;
  >img{
    height:6px;
    width:25px;
    cursor:pointer;
  }
  .btn-group{
position:absolute;
top:-3px;
&:active,  &:focus, &:active:focus {
 
  background-color:transparent;   
background:transparent ;
}

>button{
border:none;
background-color:transparent;   
background:transparent ;
box-shadow:none;
&:active,  &:focus, &:active:focus {
background-color:transparent;   
background:transparent ;
}
}
>ul{
  min-width:unset;width:135px;
  left:-105px}

  }
}

`;

const CaptainLogo = styled.img`
position:absolute;
width:24px !important;
height:24px !important;
right:-8px;
top:-8px;
`;

const CustomDropdownMenue = styled(DropdownButton)`
  width: 100%;
  text-align: left;
  margin-top: 0px;
  text-transform: capitalize;
  height: 0px;
  color: #0D4270;
  font-size:16px;
  font-family: 'rubik-medium';
border:none;
background:transparent;

  >span{
    display:none
  }

  &:active,  &:focus, &:active:focus {
    outline: none;
    border:none;
    background-color:transparent;   
background:transparent ;
    box-shadow: none;
    color: #0D4270;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #9C9C9C;
  }
`;

const CustomDropdownMenuItem = styled(DropdownItem)`
  width: 100% !important;
  text-transform: capitalize;
  > a {
    &:active, &:hover, &:focus {
      outline: none;
      color:black;
      background-color:rgba(105,194,255,0.1)!important;
      border-radius:6px !important;
    }
  }
`;

const AddMemberButton = styled.div`
cursor:pointer;
  width:190px;
  height:50px;
display:flex;
border-radius:6px;
justify-content:center;
align-items:center;
border: 1px solid rgba(0, 47, 71, 1);
color:#0D4270;
font-family:rubik-medium;
font-size:18px;
font-weight:500;
margin:15px 0;
`;

const UpdateTeamButton = styled.button`
  display:flex;
   justify-content:center;
   align-items:center;
   border:none;
   cursor:"pointer";
   background:${({ disabled }) => disabled ? "rgba(156,156,156,0.6)" : "#69C2FF"};
   border-radius:3px;
   width:425px;
   height:50px;
   font-family: 'Rubik-medium';
   font-weight: 500;
   font-size: 18px;
   line-height: 24px;
   / identical to box height, or 133% /
   text-align: center;
   color: #FFFFFF;

 `;

const TeamContributionText = styled.div`
  height: 50px;
  margin-top: 15px;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
  border-radius: 0px;
  margin-bottom: 15px;
  > div{
    text-align:Center;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
  }
`;

const PaginationCard = styled.div`
    margin-top: 35px;
    margin-bottom: 35px;
    width: 100%;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
  
`;

const UserButton = styled.button`
  background-color: ${({bgColor}) => bgColor ? bgColor: ''};
  text-transform: ${({capitalize}) => capitalize ? 'capitalize' : 'uppercase' };
  cursor: pointer;
  color: ${({textColor}) => textColor ? textColor : 'white'};
  white-space: nowrap;
  padding: ${({padding}) => padding ? padding : '4px 7px'}
  width: ${({width}) => width ? width: '100%'};
  font-size: ${({fontSize}) => fontSize ? fontSize : '9px'}
  line-height: 1.428571429;
  border-radius:  ${({radius}) => radius ? radius : '5px'};
  font-family:  ${({font}) => font ? font : `${BoldFont}`};
  font-weight: ${({font}) => font && '600'};
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '0px'};
  float: ${({float}) => float ? 'left': 'right'};
  margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};
  text-align: ${({Align}) => Align ? 'center': 'left'};
  opacity: ${({bgOpacity}) => bgOpacity ? bgOpacity: 1};
  border: ${({border}) => border ? '1px solid lightgray': 'none'};
  height: ${({ height }) => height ? height : '46px'};
  box-sizing:  ${({ boxSizing}) => boxSizing ? "border-box" : 'none'};
  img {
    height: 18px;
    width: 18px;
  }

  &:hover, &:active, &:focus{
    outline: ${({outline}) => outline ? '' : 'none'};
  }

  .send 
  {
text-transform: capitalize;   
font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;
color: #FFFFFF;
  }
`;

const SeeAllStyledModal = styled(Modal)`
  .modal-dialog {
    width:500px;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
    }
    .modal-footer{
      height:80px;
      display:flex;
      justify-content:center;
      align-items:center;
      
    }
    .modal-body{
      padding: 0px;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
      .teamNameDiv{
         width:100%;
        display:flex;
        justify-content:flex-start;
        align-items:center;
         flex-direction:column;
            >lable{
            width:100%;
            display:flex;
            margin-bottom:15px;
            justify-content:flex-start;
            align-items:center;
            font-family: 'Rubik';
               font-style: normal;
             font-weight: 400;
              font-size: 16px;
             line-height: 20px; 
             color: #0D4270;
               >span{color:red;}
            }
            >input{
              background: rgba(156, 156, 156, 0.1);
              border: 1px solid rgba(156, 156, 156, 0.4);
              border-radius:3px;
              font-family: 'Rubik-medium';
              padding:13px 15px;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #0D4270;
              width:100%;
              height:50px;
             }
           }

        .teamLogoDiv{
          margin:25px 0;
          width:100%;
          display:flex;
          justify-content:flex-start;
          align-items:center;
           flex-direction:column;
           >h4{
            width:100%;text-align:left;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #0D4270;
           }
           .radioButtonDiv{
            width:100%;
            display:flex;
            justify-content:flex-start;
            align-items:center;flex-direction:row;
            span{  width:100%;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */
            display: flex;
            align-items: center;
            /* navy */
            color: #0D4270;
              display:flex;
              justify-content:flex-start;
              align-items:center;
              flex-direction:row;}
           }

          }  
          .selectedDiv{
            display:flex;
            justify-content:flex-start;
            margin-top:17px;
            flex-wrap:wrap;
            width:450px;
           
           
          }
.logoDivInner{ display:flex;
  justify-content:flex-start;
  margin-top:17px;
  width:100%;
  height:150px;
  >img{
    width:150px;
    height:150px;border-radius:6px;

  }
  >button{margin-left:20px;
    background:${({ removeButton }) => removeButton ? "#FD7175" : "#69c2ff"};
    border:none;
    color:white;font-size:18px;
    font-family:rubik-medium;

    width:120px;
    height:50px;border-radius:4px;
    display:flex;
    justify-content:center;
    align-items:center;
   }
 }
}
    @media (max-width: 1000px) {
   width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const TeamCardNew = styled.div`
width:100%;
display: flex;
flex-wrap:wrap;
border-radius:6px;

padding: 0px 25px;
height: 550px;
overflow-x: hidden;
overflow-y: auto;
::-webkit-scrollbar {
  width: 7px;
  height: 45px;
}

::-webkit-scrollbar-thumb {
  background: #c6c2c2;
  cursor: pointer;
  border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: #f2f0f0;
  border-radius: 2.5px;
}


.headingDiv{

font-family: 'Rubik-medium';
font-weight: 500;
font-size: 16px;
line-height: 20px;

display: flex;
align-items: center;
color: #0D4270;
  .number{
    color: #69C2FF;
  }
  .max{

color: #9C9C9C;
  }
}

.teamDetails{
display: flex;
width:100%;
flex-direction:column;
align-items:center;
background:white;
}
`;

const MemberDataV3 = styled.div`
display:flex;
  align-items:center;
  width:100%;
  height:90px;
  border-top:${({ border }) => border ? "none" : "1px solid #9c9c9c"}
  .showpoint{
    display: flex;
    align-items: center
    width: 33%;
    justify-content: flex-end;
    >div{
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
      color: #69C2FF;
      margin-right: 5px;
    }
    >span{
      color: '#0D4270'
      margin-left: 5px
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
    }
  }
  >div{
    width: 16%;
  .imageDiv{   
    width:60px;
    height:60px;
    margin-right:15px;
    position:relative;
    border-radius:6px;
    border:${({ captain }) => captain ? "2px solid #69c2ff" : "none"}
    background: #69c2ff;
    div{
      img{
    width:100%;
    border-radius:4px;
    height:100%;
  }

    }
  img{
    width:100%;
    border-radius:4px;
    height:100%;
  }
}
}
  .textBox{
    width: 51%;
    .wellnessChampion{
      width: 100%;
      margin-top:4px;
  >img{
    width: 111px;
    height: 13px;
    display: flex;
  }
  >span{
  font-family: Rubik;
  font-size: 10px;
  line-height: 9px;
  color: #fff;
  margin-top: -11px;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  }}
  >div{
    margin-bottom: 10px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #69C2FF;   
  }
    >h1{
      margin:0;
      font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #0D4270;
.max{
  color: rgb(253,113,117);
 }
    };
    >h2{
      margin:0;
      font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #9C9C9C;
    }
  }

.menueButton{
  margin: auto;
  margin-right: 0px;
  display:flex;
  height:6px;
  width:25px;
  position:relative;
  >img{
    height:6px;
    width:25px;
    cursor:pointer;
  }
  .btn-group{
position:absolute;
top:-3px;
&:active,  &:focus, &:active:focus {
 
  background-color:transparent;   
background:transparent ;
}

>button{
border:none;
background-color:transparent;   
background:transparent ;
box-shadow:none;
&:active,  &:focus, &:active:focus {
background-color:transparent;   
background:transparent ;
}
}
>ul{
  min-width:unset;width:135px;
  left:-105px}

  }
}
`;

const CaptainLogoV2 = styled.img`
position:absolute;
width:24px !important;
height:24px !important;
right:-8px;
top:-8px;
`;


export { Layout, MainContainer, TopPointsContainer, TopPointsCard, TopPositionCard, FirstPositionCard, ThirdPositionCard, TopPositionProfile, TopPositionCardText, PositionCardsContainer, PositionCards, Heading, FitnessActivityCard, FitnessTopContainer, FirstPositionImage, CardWrapper, 
  FitnessTopContainerV2, TopPositionCardTextV2, OverallTeamPosition, TeamDetailsStyledModal, CustomTitle, CustomRadioButton, InputImage, SelectImage, TeamCardV2, MemberDataV2, CaptainLogo, CustomDropdownMenue, CustomDropdownMenuItem, AddMemberButton, UpdateTeamButton, TeamContributionText, PaginationCard, UserButton, SeeAllStyledModal, TeamCardNew, MemberDataV3, CaptainLogoV2};